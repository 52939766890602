import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

const IoTrace = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title="IoTrace" language={i18n.language} />
          <div className="iotrace-page">
              <div className="iotrace-page-header">
                  <div className="iotrace-page-header-text">
                      <p className="iotrace-page-header-text-name">IoTrace</p>
                      <div className="iotrace-page-header-text-vision">
                          <p>{t('iotrace-vision').toUpperCase()}</p>
                      </div>
                  </div>
              </div>
              <div className="iotrace-page-features">
                  <div className="iotrace-page-features-list">
                      <div className="iotrace-page-features-list-item">
                          <div className="iotrace-page-features-list-item-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(11, 61, 119)" preserveAspectRatio="none">
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
                              </svg>
                          </div>
                          <p>{t('iotrace-main-feature-1')}</p>
                      </div>
                      <div className="iotrace-page-features-list-item">
                          <div className="iotrace-page-features-list-item-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(11, 61, 119)" preserveAspectRatio="none">
                                  <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z" />
                              </svg>
                          </div>
                          <p>{t('iotrace-main-feature-5')}</p>
                      </div>
                      <div className="iotrace-page-features-list-item">
                          <div className="iotrace-page-features-list-item-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(11, 61, 119)" preserveAspectRatio="none">
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 2l-5.5 9h11z" />
                                  <circle cx="17.5" cy="17.5" r="4.5" />
                                  <path d="M3 13.5h8v8H3z" />
                              </svg>
                          </div>
                          <p>{t('iotrace-main-feature-2')}</p>
                      </div>
                      <div className="iotrace-page-features-list-item">
                          <div className="iotrace-page-features-list-item-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(11, 61, 119)" preserveAspectRatio="none">
                                  <path d="M0 0h24v24H0V0z" fill="none" />
                                  <path d="M19 11h-8v6h8v-6zm4 8V4.98C23 3.88 22.1 3 21 3H3c-1.1 0-2 .88-2 1.98V19c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2zm-2 .02H3V4.97h18v14.05z" />
                              </svg>
                          </div>
                          <p>{t('iotrace-main-feature-3')}</p>
                      </div>
                      <div className="iotrace-page-features-list-item">
                          <div className="iotrace-page-features-list-item-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(11, 61, 119)" preserveAspectRatio="none">
                                  <path d="M23 11.01L18 11c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-9c0-.55-.45-.99-1-.99zM23 20h-5v-7h5v7zM20 2H2C.89 2 0 2.89 0 4v12c0 1.1.89 2 2 2h7v2H7v2h8v-2h-2v-2h2v-2H2V4h18v5h2V4c0-1.11-.9-2-2-2zm-8.03 7L11 6l-.97 3H7l2.47 1.76-.94 2.91 2.47-1.8 2.47 1.8-.94-2.91L15 9h-3.03z" />
                              </svg>
                          </div>
                          <p>{t('iotrace-main-feature-6')}</p>
                      </div>
                      <div className="iotrace-page-features-list-item">
                          <div className="iotrace-page-features-list-item-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(11, 61, 119)" preserveAspectRatio="none">
                                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                              </svg>
                          </div>
                          <p>{t('iotrace-main-feature-7')}</p>
                      </div>
                      <div className="iotrace-page-features-list-item">
                          <div className="iotrace-page-features-list-item-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(11, 61, 119)" preserveAspectRatio="none">
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M2 20h20v-4H2v4zm2-3h2v2H4v-2zM2 4v4h20V4H2zm4 3H4V5h2v2zm-4 7h20v-4H2v4zm2-3h2v2H4v-2z" />
                              </svg>
                          </div>
                          <p>{t('iotrace-main-feature-4')}</p>
                      </div>
                  </div>
              </div>
              <div className="iotrace-page-overview">
                  <div className="iotrace-page-overview-text">
                      <p>{t('iotrace-overview')}</p>
                  </div>
                  <div className="iotrace-page-overview-img" />
              </div>
              <div className="iotrace-page-video">
                  <div className="iotrace-page-video-inbound">
                      <iframe src="https://www.youtube.com/embed/ZGEW5WnoFXQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                  </div>
              </div>
              <div className="iotrace-page-download">
                  <div className="iotrace-page-download-btn-long">
                      <a className="btn btn--blue" href="/products/iiot-iotrace.pdf" target="_blank" rel="noreferrer">
                          <i className="fas fa-download" />
                          {t('download-techincal-brochure')}
                      </a>
                  </div>
                  <div className="iotrace-page-download-btn-short">
                      <a className="btn btn--blue" href="/products/iiot-iotrace.pdf" target="_blank" rel="noreferrer">
                          <i className="fas fa-download" />
                          {t('techincal-brochure')}
                      </a>
                  </div>
              </div>
          </div>
      </Layout>
  )
}

export default IoTrace

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "products", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
